<script>
  import { createEventDispatcher, getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import {
    CONTEXT_KEY_USER,
    STATUS_CREATED,
    STATUS_DELIVERED,
    STATUS_IN_TRANSIT,
    TROUBLE_LOSS,
  } from "~/libs/constants";
  import { formatStringDate } from "~/libs/utils";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  /** @type {string} */
  const now = interfaceDateFormat(new Date());

  let changedEcDelivererInternalMessage;
  let statusList = [];

  // ページの初期化処理
  onMount(() => {
    changedEcDelivererInternalMessage = shipment.ecDelivererInternalMessage
      ? `${shipment.ecDelivererInternalMessage}\n\n`
      : "";
    changedEcDelivererInternalMessage += `${formatStringDate(now, "yyyy/MM/dd HH:mm")} - ${userContext.getUserName()}\n引渡予定の荷物リストには含まれていましたが、現地での引渡時には存在しませんでした。\n`;

    onInputChange();
  });

  /**
   * 日付を指定のフォーマットに変換する。
   * @param {Date} date 変換する日付
   * @returns {string} フォーマットされた日付
   */
  function interfaceDateFormat(date) {
    let year = date.getFullYear().toString();
    let month = ("0" + (1 + date.getMonth()).toString()).slice(-2);
    let day = ("0" + date.getDate().toString()).slice(-2);
    let hour = ("0" + date.getHours().toString()).slice(-2);
    let minute = ("0" + date.getMinutes().toString()).slice(-2);

    let formatStr = "YYYY-MM-DD hh:mm:00";
    formatStr = formatStr.replace(/YYYY/g, year);
    formatStr = formatStr.replace(/MM/g, month);
    formatStr = formatStr.replace(/DD/g, day);
    formatStr = formatStr.replace(/hh/g, hour);
    formatStr = formatStr.replace(/mm/g, minute);

    return formatStr;
  }

  /**
   * メモの更新イベントを発行する。
   * @returns {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報
   */
  function createUpdateShipmentEvent() {
    // 更新する荷物情報を作成
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: STATUS_CREATED,
      version: shipment.version,
    };

    updateShipmentEvent.notActuallyReceived = true;
    let extraEventStatus = (shipment.extraEvent || []).concat(
      /** @type {import("~/libs/commonTypes").ExtraEvent} */
      ({
        time: now,
        extraEventType: TROUBLE_LOSS,
      }),
    );
    updateShipmentEvent.extraEvent = extraEventStatus;
    if (
      changedEcDelivererInternalMessage != shipment.ecDelivererInternalMessage
    ) {
      updateShipmentEvent.ecDelivererInternalMessage =
        changedEcDelivererInternalMessage;
    }
    return updateShipmentEvent;
  }

  /**
   * SearchResultUpdatePattern.svelteにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet: true,
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="wrapper">
  <div class="noteArea">
    {#if shipment?.numberOfPackages > 1}
      <!-- 複数個口の場合 -->
      <div class="alertMessage">
        <span class="material-icons .md-18"> warning_amber </span>
        <p>
          複数口（全{shipment.numberOfPackages}個）の荷物です。<br />
          一部を登録する場合は通信欄に詳細を記載してください。
        </p>
      </div>
    {/if}
  </div>

  <div class="editableItemArea">
    <p class="additionalNote">登録内容を確認してください。</p>
    {#if !userContext.hasEcAdminRole()}
      {#if statusList}
        <div class="item" id="status">
          <div class="itemTh">配送／返品ステータス</div>
          <div class="itemTd">
            <div class="itemTdLeft">
              <p>
                {#if shipment.status !== STATUS_CREATED}
                  <!-- IF 変更前の配送ステータスが出荷待ち以外の場合 -->
                  <span class="oldStatus"
                    >{$_(`classes.status.${shipment.status}`)}</span
                  >→
                {/if}<span class="newStatus">出荷待ち</span>
              </p>
              {#if shipment.status !== STATUS_CREATED}
                <!-- IF 変更前の配送ステータスが出荷待ち以外の場合 -->
                <div class="initializationWarning">
                  <span class="material-icons .md-18"> warning_amber </span>
                  <div>
                    <p>以下の項目がクリアされます。</p>
                    <ul>
                      <li>荷受け（日時／場所）</li>
                      {#if shipment.status != STATUS_IN_TRANSIT}
                        <!-- IF 変更前の配送ステータスが輸送中以外の場合 -->
                        <li>持ち出し（日時／場所）</li>
                        <li>宅配ドライバー名</li>
                      {/if}
                      {#if shipment.status === STATUS_DELIVERED}
                        <!-- IF 変更前の配送ステータスが配達完了の場合 -->
                        <li>配達完了（日時／場所）</li>
                        <li>受け渡し方法（実績）</li>
                        <li>宅配ボックス番号</li>
                        <li>宅配ボックス暗証番号</li>
                        <li>置き配写真</li>
                        <li>配達票写真</li>
                      {/if}
                    </ul>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </div>
      {/if}

      <div class="item">
        <div class="itemTh">配送中の発生事象</div>
        <div class="itemTd">
          <div class="itemTdInner">
            <p>
              {formatStringDate(now, "yyyy/MM/dd HH:mm")}
            </p>
            <p class="troubleLine">
              区分：
              <b>{$_(`pages.Search.extraEventTypeLabel.${TROUBLE_LOSS}`)}</b>
            </p>
          </div>
        </div>
      </div>
    {/if}

    <p class="additionalNote">
      例文を自動入力しています。必要に応じて修正してください。
    </p>
    <!-- 通信欄 (対EC事業者) -->
    <div class="item">
      <div class="itemTh">
        通信欄<br />(対EC事業者)
      </div>
      <div class="itemTd">
        <div class="itemTdLeft column">
          <textarea
            id="communicationChange"
            wrap="soft"
            bind:value={changedEcDelivererInternalMessage}
            on:input={onInputChange}
            style="width: 476px; height: 100px; padding: 4px;"
            maxlength="20000"
          />
          <p class="note">
            ※お客様へは公開されない、EC事業者との情報共有スペース
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .noteArea {
    line-height: normal;
    .alertMessage {
      margin: 6px 0;
      padding: 8px 4px 8px 16px;
      color: #672b2a;
      background-color: #ffe7e7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      line-height: 1.4em;
    }
    .material-icons {
      font-size: 22px;
      color: #d74141;
    }
    :global(.mdc-form-field) {
      margin-left: 6px;
    }
  }
  .additionalNote {
    margin-top: 2px;
    color: #666;
  }
  .additionalNote + .item {
    padding-top: 0px;
  }
  .item {
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .itemTdLeft {
    width: 400px;
    flex-grow: 1;
    display: flex;
    color: #333;
    font-size: 13px;
    flex-direction: column;
  }
  .oldStatus {
    margin-right: 2px;
    text-decoration: line-through;
  }
  .newStatus {
    margin-left: 2px;
    font-weight: bolder;
  }
  .initializationWarning {
    margin-top: 2px;
    padding: 8px 0 8px 14px;
    color: #672b2a;
    background-color: #ffe7e7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    line-height: 1.4em;
    .material-icons {
      font-size: 22px;
      color: #d74141;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin-top: 2px;
      font-size: 12px;
    }
    li:before {
      content: "「";
    }
    li::after {
      content: "」";
    }
  }
  .itemTdInner {
    line-height: 1.4em;
    .troubleLine {
      display: flex;
    }
  }
  .note {
    margin-top: 4px;
    line-height: 1.2em;
    font-size: 11px;
    color: #666;
  }
</style>
